<template>
  <div :class="`ImageEditor __content __${image.type}`" ref="ImageEditor">
    <div class="image-edit" :style="`--zoom:${Go.onlyNum(zoom)}%;--x:${x}px;--y:${y}px;--transition:${transition}`">
      <div class="mediaContain" ref="mediaContain">
        <div class="mediaWrap">
          <img draggable="false" ref="media" class="media" :src="image.src" />
        </div>
        <div class="visibleArea" ref="area" centered></div>
      </div>
      <div w100="" sticky-bottom="">
        <div class="mediaOptions">
          <div class="icon">
            <Icon name="zoomOut" />
          </div>
          <div class="range">
            <input type="range" min="100" max="200" :value="Go.onlyNum(zoom) || 100" @input="zoomInput" />
          </div>
          <div class="icon">
            <Icon name="zoomIn" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageEditor from "./ImageEditor.js";
import ImageAdjust from "./ImageAdjust.js";
export default {
  mixins: [ImageEditor, ImageAdjust],
};
</script>

<style lang="scss">
.ImageEditor {
  --content-height: 600px;
  --editor-max-with: 540px;
  --media-height: 429px;
  --picture-size: 200px;

  &.view.default .modal-card {
    max-width: var(--editor-max-with);
    height: auto;
  }

  &.view.default .modal-card-content {
    padding: 0;
  }

  .image-edit {
    display: flex;
    height: var(--content-height);
    flex-direction: column;
    align-items: center;
  }

  .image-edit .mediaContain {
    position: relative;
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--gap);
  }

  .image-edit .mediaWrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: auto;
    max-width: var(--editor-max-with);
    height: var(--media-height);
    position: relative;
  }

  .image-edit .media {
    position: relative;
    width: 100%;
    transform: translate(var(--x), var(--y));
    width: calc(var(--zoom));
    height: auto;
    inset: 0px;
    max-width: 200%;
    transition: var(--transition);
  }

  .image-edit .visibleArea {
    position: absolute;
    box-shadow: rgba(230, 236, 240, 0.8) 0px 0px 0px 9999px;
    border: solid var(--primary-color) 3px;
    height: var(--media-height);
    width: calc(100% - calc(var(--gap) * 2));
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;
  }

  .image-edit .mediaOptions {
    margin: auto 0 0 0;
    min-height: calc(var(--gap));
    border-top: solid 1px var(--border-color);
    position: sticky;
    bottom: 0;
    z-index: 2;
    width: 100%;
    padding: var(--gap);
    display: flex;
    align-items: center;
    gap: var(--gap);
  }

  .image-edit .mediaOptions .range {
    flex: auto;
  }

  .modal-card-header-right .save-primary {
    margin: $mpadding/2 $mpadding;
  }

  &.__picture .mediaWrap {
    width: var(--picture-size);
  }

  &.__picture .visibleArea {
    width: var(--picture-size);
    height: var(--picture-size);
    border-radius: 50%;
  }
}
</style>
