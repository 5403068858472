export default {
  props: ["image", "type", "modal"],
  data: function() {
    return {
      zoom: 100,
      x: 0,
      y: 0,
      lastX: 0,
      lastY: 0,
      transition: "none",
    };
  },
  methods: {
    setOptions: function() {
      const headerRight = document.querySelector(".ImageEditor .modal-card-header-right");
      const saveButton = document.createElement("button");
      saveButton.classList.add("primary-button", "save-primary");
      saveButton.innerText = this.$locale["save"];
      headerRight.prepend(saveButton);
      saveButton.onclick = this.save;
    },
    zoomInput: function(e) {
      this.transition = "none";
      this.zoom = e.target.value;
      const media = Go.info(this.$refs.media);
      const area = Go.info(this.$refs.area);
      this.fixToTop({ media, area, x: 0, y: 0 });
      this.fixToLeft({ media, area, x: 0, y: 0 });
    },
    init: function() {
      const editor = Go.drag(this.$refs.mediaContain);

      editor.on("moveStart", (e) => {
        this.transition = "none";
      });

      editor.on("moving", (e) => {
        this.x = this.lastX + e.x;
        this.y = this.lastY + e.y;
      });

      editor.on("moveEnd", (e) => {
        this.lastX = this.lastX + e.x;
        this.lastY = this.lastY + e.y;
        this.checkBounds(e);
      });

      this.setOptions();
    },
    checkBounds: function(e) {
      this.transition = "all 0.2s ease-in-out";
      const media = Go.info(this.$refs.media);
      const area = Go.info(this.$refs.area);
      this.fixToTop({ media, area, x: e.x, y: e.y });
      this.fixToLeft({ media, area, x: e.x, y: e.y });
    },
    fixToTop: function({ media, area, y }) {
      const difHeight = media.height - area.height;
      const areaBottom = area.screenTop + area.height;
      const mediaBottom = media.screenTop + media.height;

      if (media.screenTop > area.screenTop && media.height > area.height) {
        const top = difHeight / 2;
        this.y = top;
        this.lastY = top;
        return;
      }

      if (media.screenTop > area.screenTop && media.height <= area.height) {
        this.y = 0;
        this.lastY = 0;
        return;
      }

      if (mediaBottom < areaBottom && media.height > area.height) {
        const bottom = difHeight / 2;
        this.y = Go.negative(bottom);
        this.lastY = Go.negative(bottom);
        return;
      }

      if (mediaBottom < areaBottom && media.height <= area.height) {
        this.y = 0;
        this.lastY = 0;
        return;
      }
    },
    fixToLeft: function({ media, area }) {
      const difWidth = media.width - area.width;
      const areaRight = area.screenLeft + area.width;
      const mediaRight = media.screenLeft + media.width;

      if (media.screenLeft > area.screenLeft && media.width > area.width) {
        const left = difWidth / 2;
        this.x = left;
        this.lastX = left;
        return;
      }

      if (media.screenLeft > area.screenLeft && media.width <= area.width) {
        this.x = 0;
        this.lastX = 0;
        return;
      }

      if (mediaRight < areaRight && media.width > area.width) {
        const right = difWidth / 2;
        this.x = Go.negative(right);
        this.lastX = Go.negative(right);
        return;
      }

      if (mediaRight < areaRight && media.width <= area.width) {
        this.x = 0;
        this.lastX = 0;
        return;
      }
    },
  },
  mounted: function() {
    this.zoom = this.image.zoom;
    this.x = this.image.x;
    this.y = this.image.y;
    this.$nextTick(function() {
      this.init();
    });
  },
};
