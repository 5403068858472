const Adjunst = Go.vue;

export default Adjunst;

Adjunst.methods.save = async function() {
  this.loading = await this.loader({ showProgress: "false" });
  try {
    this.dataImage = {
      zoom: this.zoom,
      x: this.x,
      y: this.y,
      type: this.image.type,
    };
    this.req = await this.$api.post(`${process.env.VUE_APP_API_HOST}/user/image-info`, this.dataImage);
    this.saved(this.req.data);
  } catch (error) {
    this.loading.close(() => {
      this.alert(Go.getErrorMessage(error));
    });
  }
};

Adjunst.methods.saved = function(data) {
  this.loading.close(async () => {
    this.alert(data);
    if (data.success) {
      this.modal.close();
      this.$store.state.appKey++;
      if (Go.is(this.image.onchange, "Function")) {
        this.image.onchange();
      }
    }
  });
};

Adjunst.methods.setPreset = function(type) {
  this.presets = {
    cover: {
      zoom: "cover",
      top: "50%",
    },
    contain: {
      zoom: "contain",
      top: "50%",
    },
  };

  this.preset = type;
  this.zoom = this.presets[type].zoom;
  this.top = this.presets[type].top;
};
